const urlMaxLength = 2000

const dashboardAuthorizerConfig = {
  validTypes: ['speaker', 'sponsor', 'exhibitor', 'homeland'],
  validRegions: ['NORTH_AMERICA_EUROPE', 'ASIA_PACIFIC', 'LATIN_AMERICA', 'AFRICA', 'MENA'],
}

const dashboardStateTypes = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  REVOKED: 'REVOKED',
}

const formsConfig = {
  largeTextBox: 1000,
  mediumTextBox: 550,
  smallTextBox: 254,
}

const callForPresentationsConfig = {
  supportedLanguages: ['en', 'fr', 'de', 'it', 'es', 'pt'],
}

const proposalTransitionIds = {
  beta: '',
  integration: 'initial-0-dcd14360-194e-11ef-912b-393ea483e1e6',
  www: 'initial-74-219982c0-12f9-11ef-ae39-bda99d568b45',
}

const submitTransitionIds = {
  integration: 'initial-4-0ec81880-194f-11ef-912b-393ea483e1e6',
  beta: 'initial-69-15e34d90-535f-11ed-a613-65043eff8f69',
  www: 'initial-69-15e34d90-535f-11ed-a613-65043eff8f69',
}

const exhibitorSubmitTransitionIds = {
  integration: 'initial-0-fbdc6d50-98f5-11ed-a23d-a501f5dfe716',
  beta: 'initial-0-fbdc6d50-98f5-11ed-a23d-a501f5dfe716',
  www: 'initial-0-fbdc6d50-98f5-11ed-a23d-a501f5dfe716',
}

const sessionStates = {
  DRAFT: 'DRAFT',
  WORKFLOW: 'WORKFLOW',
  PUBLISHED: 'PUBLISHED',
  SUBMITTED: 'SUBMITTED',
  DECLINED: 'DECLINED',
  ACCEPTED: 'ACCEPTED',
  EDITABLE: 'EDITABLE',
}

// Incoming Proposal, Rejected Proposal, Accepted Proposal, Signed Proposal, Submitted Session
const sessionWorkflowStates = {
  '0-9476c590-194e-11ef-912b-393ea483e1e6': 'SUBMITTED', // qa4
  '74-a0cb39f0-12f7-11ef-ae39-bda99d568b45': 'SUBMITTED', // www
  '1-9a8ead80-194e-11ef-912b-393ea483e1e6': 'DECLINED', // qa4
  '73-8a062bf0-d1ff-11ee-a55f-f11b96d40b80': 'DECLINED', // www
  '75-b7a942c0-12f7-11ef-ae39-bda99d568b45': 'ACCEPTED', // www
  '2-9cb2c600-194e-11ef-912b-393ea483e1e6': 'ACCEPTED', // qa4
  '3-a82dc4d0-194e-11ef-912b-393ea483e1e6': 'EDITABLE', // qa4
  '76-c862e080-12f7-11ef-ae39-bda99d568b45': 'EDITABLE', // www
  '4-': 'SUBMITTED',
  '4-af7971d0-194e-11ef-912b-393ea483e1e6': 'SUBMITTED', // qa4 functionally the same as below
  '69-0279ab00-535f-11ed-89f1-b5840d7c88e8': 'SUBMITTED', // www Assign for Review
}

const exhibitors = {
  familysearch: '0000017c-dcfc-d148-a77d-dcffcf810000',
}

export {
  urlMaxLength,
  dashboardAuthorizerConfig,
  dashboardStateTypes,
  formsConfig,
  callForPresentationsConfig,
  proposalTransitionIds,
  submitTransitionIds,
  exhibitorSubmitTransitionIds,
  sessionStates,
  sessionWorkflowStates,
  exhibitors,
}
